import MuiBadge, { BadgeProps } from "@mui/material/Badge";
import { formatNumber } from "src/core/utils/formatNumber";

/**
 * 
 * @param param0 
 * 
 * @returns 
 * 
 * @example
  <Badge
    overlap="circular"
    anchorOrigin={{ vertical: "top", horizontal: "right" }}
    sx={{ "& .MuiBadge-badge": { top: "10px", } }}>
  >
  {children}
  </Badge>
             */
const Badge = ({ children, badgeContent, ...rest }: BadgeProps) => {
  return (
    <MuiBadge
      overlap="circular"
      color="primary"
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      badgeContent={Number(badgeContent) ? formatNumber(Number(badgeContent)) : badgeContent}
      max={99999999999}
      {...rest}
    >
      {children}
    </MuiBadge>
  );
};
export default Badge;
