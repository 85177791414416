import type { ReactNode, ElementType } from "react";
import ErrorBoundary from "src/core/components/loading/error-boundary";
import LoadingScreen from "src/core/components/loading/loading-screen";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType, fallbackComponent?: ReactNode) => (props: any) =>
(

  <ErrorBoundary
    suspense={{ fallback: fallbackComponent || <LoadingScreen showWithoutDelay={true} /> }}
    component="Loadable"
    props={props}>
    <Component {...props} />
  </ErrorBoundary>
);
export default Loadable;
