import { lazy } from "react";


const DashboardLayout = lazy(() => import("src/layouts/dashboard/layout"));
const AuthGuard = lazy(() => import("src/modules/auth/guard/auth-guard"));

const UserView = lazy(() => import("src/views/user/user-view"));
const ProfilePages = lazy(() => import("src/modules/user/profile/pages"));

export const userRoutes = [
  {
    path: "/user",
    subheader: "user",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: ":uuid",
        element: <UserView />,
        children: [
          { path: "", element: <ProfilePages /> },
          { path: ":page", element: <ProfilePages /> }
        ],
      },
    ],
  },
];
