import { forwardRef } from "react";
import Avatar from "@mui/material/Avatar";
import { useTheme } from "@mui/material/styles";
import { getThumbnail, getURL } from "src/core/utils/url";
import Badge from "src/core/components/badge";
import Logo from "src/components/logo/Logo";
import { getAvatarSxProps } from "./utils";
import { CustomAvatarProps } from "./types";
import Skeleton from "@mui/material/Skeleton";

/**
 * Custom Avatar
 * 
 * A reusable avatar component with optional badge support, customizable size, shadow, and styling.
 * Includes support for thumbnail generation and modal integration.
 *
 * @component
 * @param {CustomAvatarProps} props - The properties for the component.
 * 
 * @typedef {Object} CustomAvatarProps
 * @see CustomAvatarProps
 *
 * @returns {JSX.Element} The rendered avatar component, optionally wrapped in a Badge component.
 */

const CustomAvatar = forwardRef<HTMLDivElement, CustomAvatarProps>(
  (
    { size = "md", BadgeProps, thumbnail = true, modal = false, sx, shadow, isLoading, src, ...other },
    ref
  ) => {
    const theme = useTheme();
    const url = getURL(src || "");

    const renderContent = (
      <Avatar
        src={getThumbnail(url || "")}
        alt="avatar"
        ref={ref}
        sx={{
          "&:hover": {
            opacity: 0.9,
          },
          bgcolor: "background.neutral",
          ...(shadow && { boxShadow: theme.shadows[`${shadow}`] }),
          ...getAvatarSxProps(theme, size),
          ...sx,
        }}
        children={<Logo disabledLink sx={{ p: 0.5, height: 1, width: 1 }} />}
        {...other}

        {...(isLoading && { children: <Skeleton sx={{ height: "300%", width: 1 }} /> })}
      />
    );
    return BadgeProps ? (
      <Badge
        {...BadgeProps}
      >
        {renderContent}
      </Badge>
    ) : (
      renderContent
    );
  }
);

export default CustomAvatar;

// ----------------------------------------------------------------------

// function getColorByName(name: string) {
//   const character = (name: string) => name && name.charAt(0).toUpperCase();

//   const colorByName = (name: string) => {
//     if (["A", "N", "H", "L", "Q"].includes(character(name))) return "primary";
//     if (["F", "G", "T", "I", "J"].includes(character(name))) return "info";
//     if (["K", "D", "Y", "B", "O"].includes(character(name))) return "success";
//     if (["P", "E", "R", "S", "U"].includes(character(name))) return "warning";
//     if (["V", "W", "X", "M", "Z"].includes(character(name))) return "error";
//     return "default";
//   };

//   return {
//     name: character(name),
//     color: colorByName(name),
//   } as const;
// }
