import { ClientError } from "src/model/shared/client-error";

type AsyncFunction = (...args: any[]) => Promise<any>;
type ErrorFunction = (err: any) => void;

const asyncHandler = (fn: AsyncFunction, onErrorFun?: ErrorFunction) =>
  function asyncUtilWrap(...args: any[]) {
    try {
      return Promise.resolve(fn(...args))
        .catch((err: any) => {
          if (import.meta.env.DEV) {
            // More specific error logging, assuming ClientError fits your needs
            console?.error(new ClientError({ title: "asyncHandler error:", error: err }));
          }

          // Pass error to custom error handling function if provided
          if (onErrorFun) {
            return onErrorFun(err);
          }
          return undefined; // Fallback to undefined if no custom handler
        });
    } catch (err) {
      if (import.meta.env.DEV) {
        console?.error("asyncHandler caught an unhandled exception:", err);
      }
      throw err;
    }
  };

export default asyncHandler;

