import { lazy } from "react";


// import DashboardLayout from "src/layouts/dashboard/layout";
// import RoleBasedGuard from "src/modules/auth/guard/role-based-guard";

const RoleBasedGuard = lazy(() => import("src/modules/auth/guard/role-based-guard"));
const DashboardLayout = lazy(() => import("src/layouts/dashboard/layout"));

const AwaitingApprovalNode = lazy(() => import("src/views/system/awaiting-approval/node"))
const AwaitingApprovalMembership = lazy(() => import("src/views/system/awaiting-approval/membership"))

// const PossiblePOW = lazy(() => import("src/views/editor/PossiblePOW"));
// const SearchResults = lazy(() => import("src/views/system/contributor/search-results"));

const LogView = lazy(() => import("src/views/editor/admin/LogView"));
const MaintenanceView = lazy(() => import("src/views/editor/admin/MaintenanceView"));

const AdminObjectsView = lazy(() => import("src/views/editor/admin/AdminObjectsView"));
const UsersView = lazy(() => import("src/views/editor/admin/UsersView"));
const TagsView = lazy(() => import("src/views/editor/admin/TagsView"));
const FeedbackView = lazy(() => import("src/views/feedback/FeedbackView"));

/* system  moderator */
const SnlEvents = lazy(() => import("src/views/system/moderator/snl-events/snl-events-index"));
const FixEventDates = lazy(() => import("src/views/system/moderator/temp/fix-event-dates"))
const FixEventCategories = lazy(() => import("src/views/system/moderator/temp/fix-event-categories"))

const SnlUnknown = lazy(() => import("src/views/system/moderator/snl-unknown"));

/* system  admin */
const IntroEmails = lazy(() => import("src/views/system/admin/intro-emails"));

/* system  contributor */
const ContributorPhotos = lazy(() => import("src/views/system/contributor/photos"));
const Newsletter = lazy(() => import("src/views/system/contributor/newsletter"));
const WebScrape = lazy(() => import("src/views/system//contributor/web-scrape"));
const SupportView = lazy(() => import("src/views/system/contributor/support"));

export const dashboardRoutes = [
  {
    path: "/dashboard",
    element: (
      <RoleBasedGuard roles={["sa", "sm", "sc"]}>
        <DashboardLayout />
      </RoleBasedGuard>
    ),
    children: [
      {
        path: "sys",
        //   element: <AccountPage />,
        children: [
          // { path: "sr", element: <SearchResults /> },
          // { path: "sepow", element: <PossiblePOW /> },
          { path: "web-scrape", element: <WebScrape /> },
          { path: "photos", element: <ContributorPhotos /> },
          { path: "snl", element: <Newsletter /> },
        ],
      },
    ],
  },
  {
    path: "/dashboard",
    element: (
      <RoleBasedGuard roles={["sa", "sm"]}>
        <DashboardLayout />
      </RoleBasedGuard>
    ),
    children: [
      {
        path: "sys",
        children: [
          { path: "snl-events", element: <SnlEvents /> },
          { path: "fix-event-dates", element: <FixEventDates /> },
          { path: "fix-event-categories", element: <FixEventCategories /> },
          { path: "snl-unknown", element: <SnlUnknown /> },

          { path: "feedback", element: <FeedbackView /> },
          { path: "support", element: <SupportView /> },
          {
            path: "awaiting-approval",
            children: [
              { path: "node", element: <AwaitingApprovalNode /> },
              { path: "membership", element: <AwaitingApprovalMembership /> },
            ],
          },
        ],
      },
    ],
  },

  {
    path: "/dashboard",
    element: (
      <RoleBasedGuard roles={["sa"]}>
        <DashboardLayout />
      </RoleBasedGuard>
    ),
    children: [
      {
        path: "admin",
        //   element: <RoleBasedGuard roles={["a"]} />,
        children: [
          { path: "maintenance", element: <MaintenanceView /> },

          { path: "intro-emails", element: <IntroEmails /> },
          { path: "objects", element: <AdminObjectsView /> },
          { path: "log", element: <LogView /> },
          { path: "users", element: <UsersView /> },
          { path: "tags", element: <TagsView /> },
          { path: "*" },
        ],
      },
    ],
  },
];
