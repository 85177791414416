
import { action, makeObservable, observable } from "mobx";
import axios from "src/core/utils/axios";
import { getItemAsync, setItemAsync } from "src/core/utils/storage";
import UserStore from "src/modules/auth/context/user_store";

import reject from "lodash-es/reject";
import some from "lodash-es/some";
import uniq from "lodash-es/uniq";
import { FlagProps } from "src/model/shared/flag/flag-types";




interface FlagStoreProps {
  flags: FlagProps[];
};

class FlagStore implements FlagStoreProps {
  flags: FlagProps[] = []


  isFlagged = ({ eid, fetype, type }: FlagProps) => {
    return some(this.flags, { eid, fetype, type })
  }

  add = (flag: FlagProps) => {
    this.flags = uniq([...this.flags, flag])
    // this.save(flag);
    setItemAsync("flags", this.flags);
    // if(UserStore.isAuthenticated){
    axios
      .post("/flag", { eid: flag.eid, fetype: flag.fetype, type: flag.type })
      .then(() => { })
      .catch(() => {
      });
    // }
  }

  remove = (flag: FlagProps) => {
    this.flags = reject(this.flags, { eid: flag.eid, fetype: flag.fetype, type: flag.type })
    setItemAsync("flags", this.flags);
    if (UserStore.isAuthenticated) {
      axios
        .delete("/flag", { eid: flag.eid, fetype: flag.fetype, type: flag.type })
        .then(() => { })
        .catch(() => {
        });
    }
  }


  constructor() {
    getItemAsync("flags").then(lsFlags => {
      if (lsFlags) {
        this.flags = lsFlags as any
      }
    }).catch()

    makeObservable(this, {
      add: action,
      remove: action,
      isFlagged: observable,

      flags: observable

    });
  }

}
const flagStore = new FlagStore();
export default flagStore;



