import { appConfig } from "src/config/config";
import { clearStorage } from "./storage";
import { getItem, setItem } from "./storage/local";

/** *********************** end initialize app ********************** */
const getMinorVersion = (str: string) => {
  try {
    if (str && typeof str === "string") {
      const arr = String(str).split(".");
      arr.pop();
      return arr.join(".")
    }
  } catch {/** */ }
  return undefined
};


export const initializeApp = async () => {
  const defaults = { version: appConfig.version, reloadCount: 0 }
  const localStorageApp: any = getItem("app");


  if (localStorageApp?.version) {
    const currentVersion = getMinorVersion(appConfig.version || "");
    const previousVersion = getMinorVersion(localStorageApp?.version);
    // console.log("app isEqual", { app, currentVersion, previousVersion });
    if (previousVersion && currentVersion !== previousVersion) {
      /** clear storage on minor version change */
      clearStorage();
    }
  }
  if (defaults.version !== localStorageApp?.version) {
    setItem("app", defaults);
  }

};

/** *********************** end initialize app ********************** */
/** *********************** local storage ********************** */

