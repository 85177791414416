import { Suspense, SuspenseProps } from "react";
import { ErrorBoundary as ReactErrorBoundry } from "react-error-boundary";
import DefaultErrorFallback from "src/core/components/loading/error-boundary/default-fallback";
import LoadingIndicator from "src/core/components/loading/loading-indicator";
import { logError } from "./log-error";


type ErrorBoundaryProps = {
  component: string;
  props?: any;

  children: React.ReactNode;
  fallback?: any;
  errorMessage?: string;
  resetKeys?: any[];
  suspense?: SuspenseProps;
  disableApiPost?: boolean;
  onLoading?: (loading: boolean) => void;
  [key: string]: any;
}
// React.ComponentType<FallbackProps>
/**
 * ErrorBoundary on error displays FallbackComponent and send data to api
 * @date 11/21/2022 - 10:03:13 AM
 *
 * @param {ReactNode} suspense props (size, fallback, setIsLoading)
 * @param {string[]} resetKeys keys which reset the error boundary
 * @param {ReactNode} fallback on error function to execute or component to render
 * @param {boolean} disableApiPost disable posting error to api
 * defaults to "Something went wrong"
 * @param {ReactNode} fallbackRender allows you to inline your error fallback UI into the component that's using the ErrorBoundary
 * @param {onReset} ()=>setState(defaultValues)
 * @param {rest} ...rest - props used to send to api
 * @param {any} rest.data
 * @param {any} rest.props
}
 * @returns {*}
@example `
  FallbackComponent={<ComponentWithProps />)=>getData()}
  fallbackRender={({error, resetErrorBoundary})=>getData()}
  fallback={<div>Oh no</div>}
  resetKeys={[JSON.stringify(values)]}

 */

const ErrorBoundary = ({
  suspense,
  resetKeys,
  children,
  fallback,
  FallbackComponent,
  errorMessage,
  component,
  props,
  onLoading,
  disableApiPost,
}: ErrorBoundaryProps) => {

  if (errorMessage) {
    fallback = <DefaultErrorFallback message={errorMessage} />;
  }
  if (!FallbackComponent && !fallback) {
    FallbackComponent = DefaultErrorFallback;
  }

  return (
    <ReactErrorBoundry
      fallback={fallback}

      FallbackComponent={FallbackComponent || DefaultErrorFallback}
      onError={disableApiPost ? undefined :
        (error: Error, info: any) => {
          logError(error, {
            component,
            props: { ...props, info }
          })
        }
      }
      resetKeys={resetKeys}
    >
      <Suspense {...suspense} fallback={<LoadingIndicator onIsLoading={onLoading} />}>
        {children}
      </Suspense>
    </ReactErrorBoundry>
  );
};

export default ErrorBoundary;
