
import isArray from "lodash-es/isArray";
import { getItemAsync, setItemAsync } from "src/core/utils/storage";
import { axiosDataParser } from "./data-parser";
import axiosInstance from "./instance";
import { AxiosMethod, AxiosProps } from "./types";


const returnData = (res: any, first_row: boolean) => {
    if (isArray(res.data)) {
        if (res.data.length > 0) {
            return axiosDataParser(res.data, first_row);
        }
        return null;

    }

    return res.data;
};

export const apiResponse = async (method: AxiosMethod, url: string, _data?: any, first_row = false,
    axiosProps?: AxiosProps
) => {
    const data = _data ? JSON.parse(JSON.stringify(_data)) : _data;

    let params: any;
    if (["delete"].includes(method)) {
        params = { data };
    } else if (["get", "head", "options"].includes(method)) {
        params = { params: data, ...axiosProps };
    } else if (["post", "put", "patch"].includes(method)) {
        params = data;
    }

    const key = JSON.stringify({ url, data });
    const { cache } = axiosProps || {}

    try {
        const res = await axiosInstance[method](url, params); // props

        const _data_ = returnData(res, first_row);

        if (_data_ && cache !== "never" && !url.includes("/search")) {
            /** disable caching for /search routes, manual cache disable, and for no data */
            setItemAsync(key, _data_);
        }


        return _data_
    } catch (err) {

        const { code } = err || {}
        if (code === "auth/id-token-expired") {
            const windowRefreshToken = window.__refreshToken__;
            if (windowRefreshToken) {
                await windowRefreshToken(true);
                return apiResponse(method, url, data, first_row)
            }
        } else {
            const li = await getItemAsync(key);
            if (li) {
                return li;
            }
        }
        throw err
    }
};
