import MuiAlert, { AlertProps } from "@mui/material/Alert/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { toast as sonnerToast } from "sonner";
import IconButton from "src/core/components/icon/icon-button";

type VariantType = "info" | "success" | "warning" | "error";
type AlertPropsType = Omit<AlertProps, "onClose" | "action"> & {
  onClose?: (p?: any) => void;
  action?: Omit<ButtonProps, "onClick"> & {
    onClick?: any
  };
  title?: string;
};


type AlertType = {
  toastId: string | number,
  message: string,
  variant?: VariantType,
  props?: AlertPropsType
}
const Alert = ({ toastId, message, variant, props }: AlertType) => {

  const { title, onClose, action } = props || {};


  return (
    <MuiAlert
      sx={{
        width: 1,
        minWidth: 300,
        "& .MuiAlert-action": {
          p: 0,
        },
      }}
      severity={variant}
      action={<Stack direction={"row"}>
        {action && (
          <Button
            {...action}
            onClick={() => {
              if (action?.onClick) {
                action.onClick()
              }
              sonnerToast.dismiss(toastId);
            }}
          />
        )}

        <IconButton icon="close" onClick={() => {
          if (onClose) onClose()
          sonnerToast.dismiss(toastId)
        }} />
      </Stack>

      }
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {message}
    </MuiAlert>
  )
}
export const toast = (message: string, variant?: VariantType, props?: AlertPropsType) => {
  const { onClose } = props || {};
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  }


  const toastId = sonnerToast.custom((sonnerToastId) => (<Alert
    toastId={sonnerToastId}
    message={message}
    variant={variant}
    props={props}
  />
  ), {
    onDismiss: () => { /** onDismiss gets fired when the toast is swiped */
      console.log("onDismiss")
      handleClose()
    },
    onAutoClose: () => { /** firest when timer expires */
      console.log("onAutoClose")
      handleClose()
    },

  });
  return toastId
};

export function info(message: string, props?: AlertPropsType) {
  toast(message, "info", props);
}
export function success(message: string, props?: AlertPropsType) {
  toast(message, "success", props);
}
export function warning(message: string, props?: AlertPropsType) {
  toast(message, "warning", props);
}
export function error(message: string, props?: AlertPropsType) {
  // sonnerToast.error(message);
  toast(message, "error", props);
}
