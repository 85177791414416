
import { action, makeObservable, observable, runInAction } from "mobx";

import { createContext, useContext } from "react";
import { MenuOptionsType, MenuPropsTypes } from "./types"

let timer
const defaultProps: MenuPropsTypes = {
  side: "right",
};
class MenuClass {
  anchorEl: null | any = null; //   anchorEl: null | HTMLElement;
  subheader?: string;
  options?: MenuOptionsType["options"];
  props: MenuPropsTypes = defaultProps;

  /**
       *
       * @param Menu
       * @param listOptions string[] || {primary: "hello", icon:"star"}
       * @param listOptions.options?: ListItemType[];
       * @param listOptions.getOptions?: ()=> ListItemType[];
       * @param listOptions.getOptionsAsync?: ()=> Promise<ListItemType[]>;
      
       * @param props
       * @examples: 
            const options: ListItemType[] = [
            { primary: "Edit profile", icon: "user" },
            { divider: true },
            {
              primary: "Help & Support",
              icon: "circle-question",
              options: [
                { primary: "Help Center", icon: "circle-question", href: "/temp" },
              ],
            },
            { component: <Caption>footer component</Caption> },
          ];
          MenuStore.set(e.currentTarget, {
            subheader: "Subheader", 
            
            options: options,
            ##or##
            getOptions: async()=> return axios.get(/??): ListItemType[]
          })
        */

  set(anchorEl?: any, listOptions?: MenuOptionsType, props?: MenuPropsTypes) {
    if (timer) clearTimeout(timer);

    this.props = { ...defaultProps, ...props };
    this.anchorEl = anchorEl?.currentTarget || anchorEl?.target || anchorEl;

    const { subheader, options, getOptions } = listOptions || {};
    this.subheader = subheader;

    if (options) {
      this.options = options;
    } else if (getOptions) {
      getOptions()
        .then((res) => {
          if (res && Array.isArray(res)) {
            runInAction(() => {
              this.options = res;
            })
          }
        })
        .catch(() => { });
    }
  }

  clear() {
    this.anchorEl = undefined;
    /** When the options are cleared, the menu height reduces to its minimal size, 
     * making the animation less visually appealing. */
    timer = setTimeout(() => {
      this.options = undefined;
    }, 2000)

  }

  constructor() {
    makeObservable(this, {
      set: action,
      clear: action,

      anchorEl: observable,
      props: observable,
      options: observable,
    });
  }
}

export const MenuStore = new MenuClass();

const MenuContext = createContext(MenuStore);

type Props = {
  children: React.ReactNode;
};

export const MenuProvider = ({ children }: Props) => (
  <MenuContext.Provider value={MenuStore}>{children} </MenuContext.Provider>
);

export const useMenuContext = () => {
  const context = useContext(MenuContext);

  if (!context) throw new Error("useMenuContext must be use inside UserProvider");

  return context;
};
