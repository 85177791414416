
export const appConfig = {
  title: `MasjidPlus`,
  slogan: "Come to prayer! Come to success!",
  description: `Are you new in town, looking for meaningful connections in our community or simply looking for new activities to be part of? MasjidPlus provides access to all Mosque or Prayer Places activities at your fingertips and helps you find one closest to you, happening now. `,

  email: "masjidplus@gmail.com",
  version: import.meta.env.VITE_VERSION,
  date: import.meta.env.VITE_APP_DATE,
  // parallax: {
  //   height: 350,
  // },
  // popover: {
  //   width: 360,
  // },

  image: {
    invalid: "/public/default/image-not-found.webp",
    // logo:"/public/default/color/mp.png",
    avatar: {
      // default: "src/assets/images/default.png",
      // default: "/public/default/color/logo.png",
      default: "/public/default/default.png",
      logo: "/public/default/logo.png",
    },
    parallax: {
      default: "/public/default/cover/gradient.webp",
      pow: "/public/default/color/banner/100207.png",
      post: "/public/default/color/banner/100207.png",
      event: "/public/default/color/background/100057.png",
      events: "/public/default/color/background/100057.png",
      school: "/public/default/color/background/100057.png",
      schools: "/public/default/color/background/100057.png",
    },
    user: {
      logo: "/public/default/avatar.png",
      cover: "/public/default/cover/user-1.webp",
    },
    pow: {
      logo: "/public/default/logo.png",
      cover: "/public/default/cover/masjid-1.webp",
    },
    event: {
      thumbnail: "/public/default/cover/gradient.webp",
    },
  },
  modules: {
    autoLogin: true,
    includeDemoRoutes: true
  }
};


