
import { LRUCache } from 'lru-cache'
const options = {
    max: 500, // Store up to 500 items
    maxSize: 5000,
    // No ttl defined, so items stay until evicted by size
    sizeCalculation: (value, key) => {
        return 1
    },
}
const cache = new LRUCache(options)



export default cache;

/** lodash memoize
import memoize from "lodash-es/memoize";
const getApiResponse = async (_memoKey: string, url: string, data?: any, first_row = false, axiosProps?: AxiosProps) => {
  return apiResponse("get", url, data, first_row, axiosProps); // props
};
const memoizedApiResponse = memoize(getApiResponse);


 */