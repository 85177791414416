
import { action, makeObservable, observable, runInAction } from "mobx";

import filter from "lodash-es/filter";
import lodashIncludes from "lodash-es/includes";
import { createContext, useContext } from "react";
import { SITE_OPTIONS, SiteOptionsType } from "src/config/site-options";
import { getItemAsync, setItemAsync } from "src/core/utils/storage";
import { setItem } from "src/core/utils/storage/local";


const STORAGE_NAME = "site-options";
class SiteOptionsStoreClass {
  options: SiteOptionsType[] = [];

  hasOption = (o: SiteOptionsType) => {
    return lodashIncludes(this.options, o);
  };

  #set = {
    options: (o) => {
      runInAction(() => {
        this.options = o;
      })
    }
  }
  option = {
    get: () => {
      return filter(this.options, (f) => SITE_OPTIONS.includes(f));
    },
    remove: (v: SiteOptionsType) => {
      if (this.hasOption(v)) {
        const o = this.option.get();
        this.#set.options(filter(o, (f) => f !== v));
        setItemAsync(STORAGE_NAME, [...this.options]);
        setItem(STORAGE_NAME, [...this.options]);
      }
    },
    add: (v: SiteOptionsType) => {
      if (!this.hasOption(v)) {
        this.#set.options([...this.options, v]);
      }
      setItemAsync(STORAGE_NAME, [...this.options]);
      setItem(STORAGE_NAME, [...this.options]);
    },
  };

  toggle = (v: SiteOptionsType) => {
    /** exclude any value which is not in SITE_OPTIONS */

    if (this.hasOption(v)) {
      this.option.remove(v);
      return;
    }
    this.option.add(v);
  };


  loadData = async () => {

    const o: SiteOptionsType[] = await getItemAsync(STORAGE_NAME);
    if (o) {
      this.#set.options(o)
    }
  };

  constructor() {
    this.loadData();

    makeObservable(this, {
      options: observable,
      hasOption: observable,
      toggle: action,
    });


  }
}

export const SiteOptionsStore = new SiteOptionsStoreClass();

const SiteOptionsContext = createContext(SiteOptionsStore);

type Props = {
  children: React.ReactNode;
};
export const SiteOptionsProvider = ({ children }: Props) => {
  return (
    <SiteOptionsContext.Provider value={SiteOptionsStore}>{children}</SiteOptionsContext.Provider>
  );
};

export const useSiteOptionsContext = () => {
  const context = useContext(SiteOptionsContext);

  if (!context) throw new Error("useSiteOptionsContext must be use inside SiteOptionsProvider");

  return context;
};
