import ThemeProvider from "src/core/theme";
import { SearchAppBarProvider } from "../core/components/search/search-drawer/search-app-bar-context";
import { MenuProvider } from "src/core/components/menu/menuContext";
import { NotificationsProvider } from "../modules/notifications/notificationsContext";
import { LightboxProvider } from "./lightboxContext";
import { SiteOptionsProvider } from "./siteOptionsContext";
import { SettingsProvider } from "src/core/theme/settings/SettingsContext";
import { NavigationProvider } from "src/context/navigation_store";
import { FlagProvider } from "src/context/flagContext";
import { UserProvider } from "src/modules/auth/context/userContext";
import { FormProvider } from "src/core/forms/formContext";
import { EditFormProvider } from "src/core/components/forms/edit/editFormContext";



const combineComponents = (...components: any) =>
  components.reduce(
    (AccumulatedComponents: any, CurrentComponent: any) =>
      ({ children }: any) => (
        <AccumulatedComponents>
          <CurrentComponent>{children}</CurrentComponent>
        </AccumulatedComponents>
      ),
    ({ children }: any) => <>{children}</>
  );

const providers = [// 0.2mb is standard all provides include
  /** all providers together 2.4mb */
  SettingsProvider,// 0mb
  LightboxProvider, //0.2mb
  SiteOptionsProvider,//0.3mb
  FormProvider, // //0.1mb
  MenuProvider,// 0.2mb
  FlagProvider, // .2mb
  NotificationsProvider, // .6mb
  NavigationProvider,// .2mb
  ThemeProvider, //2.3mb no way around this
  SearchAppBarProvider, // 2.4mb


  UserProvider, // 0.6mb
  EditFormProvider









];

const AppContextProvider = combineComponents(...providers);
export default AppContextProvider;
