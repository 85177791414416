

import { axiosErrorLogger } from "./error-logger";
import { AxiosProps } from "./types";
import { apiResponse } from "./api-response";
import cache from "./cache"
/**
 * Usage
  `if(HAS_MORE){
     HAS_MORE = axios.hasMore(data, limit)
  }`
 * @param data u
 * @param limit 
 * @returns 
 */
const hasMore = (data: any[], limit: number) => {
  if (data && data.length === limit) {
    return true;
  }
  return false;
};
const getPage = (data: any[], limit: number) => {
  return data.length / limit;
};




const generateCacheKey = (url: string, data?: any) => {
  return JSON.stringify({ url, data });
};
const fetchingURLs: Set<string> = new Set();
const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const API = {
  cached: async (url: string, data?: any, first_row = false, axiosProps?: AxiosProps) => {
    const cacheKey = generateCacheKey(url, data);

    // Wait if the same request is currently being fetched
    while (fetchingURLs.has(cacheKey)) {
      await sleep(100);
    }
    const cachedRes = cache.get(cacheKey);
    if (cachedRes) {
      return cachedRes;
    }
    // Mark the request as in progress
    fetchingURLs.add(cacheKey);
    try {
      // Fetch the data
      const res = await apiResponse("get", url, data, first_row, axiosProps);
      // Store the response in the cache
      cache.set(cacheKey, res);

      return res;

      // eslint-disable-next-line no-useless-catch
    } catch (error) {
      throw error; // Propagate the error
    } finally {
      // Mark the request as complete
      fetchingURLs.delete(cacheKey);
    }
  },

  // cached: (url: string, data?: any, first_row = false, axiosProps?: AxiosProps) =>
  //   memoizedApiResponse(getCacheKey(url, data), url, data, first_row, axiosProps),
  cache: {
    update: (url: string, data?: any, value?: any) => {
      const key = generateCacheKey(url, data)
      cache.set(key, Promise.resolve(value));
    },
    clear: () => {
      cache?.clear();
    },
    //** lodash memoize version */
    // update: (url: string, data?: any, value?: any) => {
    //   const key = generateCacheKey(url, data)
    //   memoizedApiResponse.cache.set(key, Promise.resolve(value));

    // },
    // clear: () => {
    //   if (memoizedApiResponse.cache?.clear) {
    //     memoizedApiResponse.cache?.clear();
    //   }
    // },
    // delete: (url: string, data?: any) => {
    //   if (memoizedApiResponse.cache?.delete) {
    //     memoizedApiResponse.cache?.delete(JSON.stringify({ url, data }));
    //   }
    // },
  },
  get: (url: string, data?: any, first_row = false, axiosProps?: AxiosProps) => apiResponse("get", url, data, first_row, axiosProps),
  head: (url: string, data: any, first_row = false) => apiResponse("head", url, data, first_row),
  options: (url: string, data: any, first_row = false) =>
    apiResponse("options", url, data, first_row),
  delete: (url: string, data: any, first_row = false) =>
    apiResponse("delete", url, data, first_row),
  post: (url: string, data: any, first_row = false) => apiResponse("post", url, data, first_row),
  put: (url: string, data: any, first_row = false) => apiResponse("put", url, data, first_row),
  patch: (url: string, data: any, first_row = false) => apiResponse("patch", url, data, first_row),
  hasMore,
  getPage,
  error: axiosErrorLogger,
};

const api = API.cached;
export default API;
export { api, API as axios };

