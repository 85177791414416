import { info as alertInfo } from "src/core/components/alert";
import axios from "src/core/utils/axios";
import { ClientError } from "src/model/shared/client-error";


export const logError = (error: Error, props: any) => {


  if (error.stack?.includes("assets/")) {
    console.warn("Resource failed to load, reloading page...");
    console.warn({
      name: error?.name,
      message: error?.message,
      stack: error?.stack,
      error: error
    })

    alertInfo("A new version of the app is available. Please reload the page to access the latest features and improvements.",
      {
        title: "App Update Available!",
        onClose: () => {
          window.location.reload();
        },

      })
    return;
  }

  const ce = new ClientError({
    title: "ErrorBoundary errorHandler", // error.message
    props: props,
    error,
  });
  console.debug("ClientError", ce);

  try {
    // console.debug("ErrorBoundary props :::", rest);
    // console.debug("ErrorBoundary error :::", error);
    // console.debug("ErrorBoundary stack :::", info.componentStack);
  } catch {
    /** */
  }
  if (import.meta.env.PROD) {
    axios.error(ce);
  }
};


