

import { lazy } from "react";
import { useRoutes } from "react-router-dom";

import { getLocalSettings } from "src/core/hooks/useLocalSettings";
import { settingsRoutes } from "src/modules/settings/settings-routes";

// ----------------------------------------------------------------------
import { authRoutes } from "./auth";
import { dashboardRoutes } from "./dashboard";
import { userRoutes } from "./user";

const DashboardLayout = lazy(() => import("src/layouts/dashboard/layout"));

const CompactLayout = lazy(() => import("src/layouts/compact"));
const SimpleLayout = lazy(() => import("src/layouts/simple"));

const Page404 = lazy(() => import("src/core/pages/Page404"));

const LandingView = lazy(() => import("src/views/landing/landing-index"));
const LandingManagementView = lazy(() => import("src/views/landing/management/management-index"));

const HomeView = lazy(() => import("src/views/home/home-view"));
const HomeExplore = lazy(() => import("src/views/home/home-explore"));
const WorkingOn = lazy(() => import("src/views/WorkingOn"));
// import AutoLayout from "src/layouts/AutoLayout";

const NotificationsPage = lazy(() => import("src/modules/notifications/page/notifications-page"))
const FeaturesView = lazy(() => import("src/views/features/FeaturesView"));

const EventsView = lazy(() => import("src/views/events/events-view"));
const MapsView = lazy(() => import("src/views/maps/maps-view"));
// const TagsView = lazy(() => import("src/views/tags/TagsView"));

const EventView = lazy(() => import("src/views/event/EventView"));

const PostView = lazy(() => import("src/views/post/PostView"));
const DigitalDisplayView = lazy(() => import("src/views/pow/screens/digital-display-view"))

const DigitalDisplayPageView = lazy(() => import("src/views/pow/screens/digital-display-page-view"))
/** groups */
const PowView = lazy(() => import("src/views/groups/view-pow"));
const GroupView = lazy(() => import("src/views/groups/view-group"));
// ----------------------------------------------------------------------
const landingPageDisabled = getLocalSettings("landingPageDisabled", false);

const landingPages = [
  {
    path: "/landing",
    element: (
      <SimpleLayout>
        <LandingView />
      </SimpleLayout>
    ),
  },
  {
    path: "/landing/management",
    element: <LandingManagementView />,
  },
]
export default function Router() {
  return useRoutes([
    {
      ...(!landingPageDisabled && {
        path: "/",
        element: <LandingView />,
      }),
    },
    ...dashboardRoutes,
    ...authRoutes,
    ...userRoutes,
    // ...demoRoutes,
    ...settingsRoutes,
    ...landingPages,
    {
      path: "digital-display/page/:nid",
      element: <DigitalDisplayPageView />,
    },
    {
      path: "digital-display/:nid",
      element: <DigitalDisplayView />,
    },

    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        {
          path: "/",
          element: <HomeView />,
        },
        {
          path: "/home",
          element: <HomeView />,
        },
        {
          path: "/explore",
          element: <HomeExplore />,
        },
        {
          path: "/",
          // element: <RoleBasedGuard roles={["a"]}/>,
          children: [
            {
              path: "workingon",
              // element: <AuthGuard><WorkingOn /></AuthGuard>,
              element: <WorkingOn />,
            },
          ],
        },

        { path: "/notifications", element: <NotificationsPage /> },
        { path: "/roadmap", element: <FeaturesView /> },
        { path: "feedback/*", element: <PostView /> },

        //     //  { path: "feeds", element: <FeedsPage /> },
        //     { path: "businesses", element: <BusinessesView /> },

        { path: "events/*", element: <EventsView /> },
        { path: "event/*", element: <EventView /> },

        { path: "page/*", element: <PostView /> },
        { path: "snl/*", element: <PostView /> },
        { path: "janaza/*", element: <PostView /> },
        { path: "post/*", element: <PostView /> },
        { path: "lecture/*", element: <PostView /> },
        { path: "map", element: <MapsView /> },
        // { path: "tags", element: <TagsView /> },
        // { path: "tag/:tagid", element: <TagsView /> },
        {
          path: "masjid/:title",
          element: <PowView />,
          children: [{ path: "*", element: null }],
        },
        {
          path: "group/:title",
          element: <GroupView />,
          children: [{ path: "*", element: null }],
        },
        {
          path: "school/:title",
          element: <GroupView />,
          children: [{ path: "*", element: null }],
        },
      ],
    },


    {
      element: <CompactLayout />,
      children: [
        { path: "*", element: <Page404 /> },
      ],
    },
    // // // { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
